<template>
  <v-container>
    <v-row align="center">
      <v-col>
          <div v-show=showTime id="countdown">
            <div id="countdown-number"></div>
            <svg>
              <circle r="18" cx="20" cy="20"></circle>
            </svg>
          </div>    
      </v-col>
      <v-col>
        <v-img :src="require('@/assets/newLogo.png')" class="my-3" contain height="100"/>
      </v-col>
      <v-col>
        <v-img :src="require('@/assets/language.png')" class="my-3" contain height="48"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useToast } from "vue-toastification";
import apiCaravel from '@/services/apiCaravel';
const toast = useToast();

export default {
  name: 'GeneralTimer',
   computed: {
    activeOrderTime: {
      get() {
        return this.$store.getters.getActiveOrderTime;
      }
    },
  },
  data(){
    return {
      timerIntervalId: null,
      expiredOrder: false,
      checkTime:0,
    }
  },
  mounted(){
      this.startTimerFromMounted()
    },
  methods:{
    startTimer: function(duration, display){
      var timer = duration, minutes, seconds
      var self = this; 
      setInterval(() => {
        minutes = parseInt(timer/60, 10);
        seconds = parseInt(timer%60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        
        display.textContent = minutes + ":" + seconds;
    
        if(timer >= 0 && this.activeOrderTime.startRequest == true) {
          this.checkOrderStatus()
        }
        
        if (timer == 300){
          toast.warning("Faltam 5 minutos para seu pedido expirar.");
        }

        if (timer == 60){
          toast.warning("Falta 1 minuto para seu pedido expirar.");
        }

        if (--timer == 0){
          this.$store.commit("setExpired", true);
          toast.warning("Seu pedido foi expirado. Retorne a sua plataforma e efetue um novo pedido.");
          self.$router.push('/expiredbudget');
          
          this.startTimerFromMounted()
          timer--;
        }        
      }, 1000);
    },
    async checkOrderStatus(){ 
      this.checkTime++; //Contador

      if (this.checkTime % 30 === 0) {

        var token = 'Bearer Auha88daHADuahwDQ0ZD877siDii8dabASJ7DSY6GSD4S65DADg9s'
        var headers = {}
        headers['Authorization'] = token
        
        
        var response = await apiCaravel.get('/api/order/'+this.activeOrderTime.orderID, {headers})

        if(response.data.data.status == "filled"){
          let params = {
            startRequest: false
          }
          var self = this; 
          this.$store.commit("setActiveOrderTime", params);
          toast.success("O seu pagamento foi detectado. O saldo será creditado em sua respectiva plataforma.");
          self.$router.push('/payments/sucess')
        }
      }
    },
    startTimerFromMounted: function() {
        let endTime = this.startTime + this.duration * 15000;
        let dataAtual = new Date().getTime();
        let currentDuration = ((endTime - dataAtual) / 1000).toFixed(0);
        var display = document.querySelector("#timer");
        display = document.querySelector("#countdown-number");
        this.timerIntervalId = this.startTimer(currentDuration, display);
    }
  },
  watch: {
    
  },
  props:{
    startTime: Number,
    duration: Number,
    showTime: Boolean,
  }
}
</script>
<style scoped lang="scss">
#border-timer{
  width: 50px;
  height: 50px;
  border: 1px solid rgba(66, 133, 244, 0.6);
  border-radius: 50%;
  background: black;
  vertical-align: middle;
  display: table-cell;
}
#timer{
  color:white;
  text-align: center;
}
#countdown {
  position: relative;
  margin: auto;
  height: 40px;
  width: 40px;
  text-align: center;
}

#countdown-number {
  color: white;
  font-size: small;
  display: inline-block;
  line-height: 40px;
}

svg {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  transform: rotateY(-180deg) rotateZ(-90deg);
}

svg circle {
  stroke-dasharray: 113px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 2px;
  stroke: white;
  fill: none;
  animation: countdown 60s linear infinite forwards;
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 113px;
  }
}
</style>
