import { createRouter, createWebHistory } from 'vue-router'
import MeansPayment from '../views/MeansPayment.vue'

const routes = [
  {
    path: '/',
    name: 'invalidURL',
    component: () => import(/* webpackChunkName: "about" */ '../views/InvalidURL.vue')
  },
  {
    path: '/:token',
    name: 'meansPayment',
    component: MeansPayment
  },
  {
    path: '/pix/',
    name: 'pix',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PixView.vue')
  },
  {
    path: '/cryptocurrency/',
    name: 'cryptocurrency',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CryptoCurrency.vue')
  },
  {
    path: '/cryptopaymentdata/',
    name: 'cryptopaymentdata',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CryptoPaymentData.vue')
  },
  {
    path: '/cryptoselect/',
    name: 'cryptoselect',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CryptoSelect.vue')
  },
  {
    path: '/expiredbudget/',
    name: 'expiredbudget',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ExpiredBudget.vue')
  },  
  {
    path: '/payments/sucess/',
    name: 'sucess',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SucessPayment.vue')
  },
  {
    path: '/payments/error/',
    name: 'error',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ErrorPayment.vue')
  },
  { 
    path: '/simulate/deposit/',
    name: 'deposit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Deposit.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'invalidURL',
    component: () => import(/* webpackChunkName: "about" */ '../views/InvalidURL.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
