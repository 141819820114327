<template>
  <div>
    <GeneralTimer :showTime="true" :duration=this.duration :startTime=this.startTime />
    <div>
      <p class="text-center white-text">
        COMPLETE O SEU PAGAMENTO
      </p>
      <p class="text-center white-text">
        R$ {{ value }} BRL
      </p>      
      <p class="text-center white-text">
        SELECIONE O MEIO DE PAGAMENTO
      </p>  
    </div>
    <div>
      <!-- Select main page -->
        <div v-for="currency in coins" :key="currency.id">
            <v-btn class="btn-select" block variant="outlined" @click="coinPage(currency.name)">{{currency.name}}</v-btn>
        </div>
    </div>
  </div>
</template>

<script>
import GeneralTimer from '../components/GeneralTimer.vue'
export default {
  name: 'TitleMeansPayment',
  computed: {
    duration: {
      get() {
        return this.$store.getters.getDuration;
      }
    },
    startTime: {
      get() {
        return this.$store.getters.getStartTime;
      }
    },
  },
  methods: {
      coinPage: function(moeda){
          this.$router.push('/'+moeda.toLowerCase().replace(/[\u0300-\u036f]/g, "").replace(" ",""))
      }
  },  
  components: {
    GeneralTimer
  },
  props:{
    value: String,
    coins: Array
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


</style>
