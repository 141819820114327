import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import '@/assets/scss/main.scss'

loadFonts()

const options = {
  // You can set your default options here
  integration: {
    'type': "deposit",
    'ledger_type': "EWALLY",
    'method_value': "pix"    
  }  
};

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(Toast, options)
  .mount('#app')
