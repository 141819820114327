<template>
  <v-app class="mainBackground">
    <v-main class="centerModal">
      <v-container>
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
}
</script>
<style>
.mainBackground{
  background-image: linear-gradient(to bottom , rgba(40, 40, 94, 1), #558FCC 50%);
}
.centerModal{
  display: flex;
  justify-content: center;
  align-items: center;
  zoom: 70%;
}
</style>